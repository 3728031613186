.about_wrapper {
  background-image: url("../images/mapdots.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;

  .about-heading {
    line-height: 42px;
  }
  .sub-text {
    font-weight: 700;
    color: $theme-color;
  }

  .service-box {
    // border: 1px solid $theme-color;
    background-color: rgba(255, 255, 255, 0.9);
    padding: 1rem;
    margin: 0.5rem;
    box-shadow: 0 1px 2px rgb(0 0 0 / 25%), 0 -1px 1px rgb(0 0 0 / 13%);
    transition: all 0.4s ease-in-out;

    @media #{$xs-med-layout} {
      margin: 0.5rem auto;
    }

    h6 {
      color: $theme-color;
      transition: all 0.3s ease-in-out;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 12px;
      justify-content: center;
    }
    .about-icon {
      font-size: 2.97rem;
      color: $theme-color;
    }
    .about-text {
      font-size: 17px;
      padding: 0.5rem;
      line-height: 1.4;
      text-align: center;
    }

    &:hover {
      transform: scale(1.05);

      h6 {
        color: $theme-hover-color;
      }
    }
  }
}

.about {
  background-color: #f8fbfd;
  padding: 2rem;
}

// .about-content {
//   padding: 1rem;
//   width: 50%;
// }

.about-page {
  padding: 2rem 0;
  height: 100%;
}

//   about page ///////////////////////////////////////////////////
// .about-section,
// .join-section {
//   margin-bottom: 4rem;
// }

.about-text,
.join-text {
  width: 100%;
  // padding: 1rem;
}

.about-image img {
  width: 80%;
  height: 80%;
}

.book-img {
  width: 100%;
  height: 80%;
}

@media (max-width: 768px) {
  .about-quality {
    grid-template-columns: auto;
  }
}

.about-quality p {
  font-size: 18px;
  color: #666;
  line-height: 1.79;
}

// .about-text-para p{
//     font-size: 1.1rem;
//     font-family: Poppins;
//     color: #444;
// }

@media only screen and (max-width: 768px) {
  .about-page {
    padding: 1rem 0;
  }
  .about-section,
  .join-section {
    padding: 0 1.5rem;
    flex-direction: column;
    justify-content: space-around;
    margin-bottom: 2rem;
  }

  // .about-image {
  //   display: none;
  // }

  .about-image img {
    width: 100%;
    height: 100%;
  }

  .about-text-para p {
    font-size: 1rem;
  }
}

// about join ///////////////////////////////////////////

.about-join {
  background: #f4f6f8;
  padding: 2rem 0;

  .join-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .img-div {
    width: 180px;
    height: 180px;
  }

  h4 {
    margin-top: 2rem;
    font-size: 25px;
    line-height: 1.2;
    font-family: "Poppins";
    font-weight: 700;
    color: #131c21;
    font-style: normal;
  }
}
.join-head {
  text-align: center;
  margin-bottom: 4rem;
}

.join-content {
  text-align: center;
  /* background: #777; */
  // padding: 1rem;
}

.join-content-1 .about-icon {
  font-size: 7rem;
  border: 3px solid rgba(0, 0, 0, 0.8);
  border-radius: 50%;
  padding: 1.5rem;
}

@media only screen and (max-width: 768px) {
  .about-join {
    padding: 1rem;
  }
  .join-content {
    flex-direction: column;
  }
}

.about-page-wrapper {
  .heading {
    h2 {
      color: #000;
      font-weight: 400;
      font-size: 3rem;
      text-align: center;

      @media #{$xs-med-layout} {
        font-size: 2rem;
        line-height: 2.5rem;
      }

      span {
        color: #1a4789;
        font-weight: 600;
      }
    }
    h4 {
      color: #000;
      font-size: 2rem;
      text-align: center;
      font-weight: 400;

      @media #{$xs-med-layout} {
        font-size: 2rem;
      }
    }
  }
  .scan-data {
    h4 {
      color: #000;
      font-size: 2.25rem;
      font-weight: 400;
      text-align: center;
      line-height: 3.25rem;

      @media #{$xs-med-layout} {
        margin-top: 1rem;
        font-size: 2rem;
        line-height: 2.5rem;
      }

      span {
        font-weight: 600;
        color: #1a4789;
      }
    }
    .button-div {
      display: flex;
      align-items: center;
      gap: 1rem;
      // border: 1px solid red;
      justify-content: center;

      @media #{$xs-med-layout} {
        flex-direction: column;
      }

      button {
        background-color: #f21f22;
        color: #fff;
        border: none;
        border-radius: 0.61rem;
        padding: 0.75rem 1rem;
        font-weight: 500;
        font-size: 1.5rem;
        text-transform: uppercase;

        .download-icon {
          font-size: 2rem;
        }
      }
      p {
        font-size: 1.5rem;
        color: #000;
        font-weight: 500;
        margin-bottom: 0;
      }
    }
  }

  .scanner-div {
    // border: 1px solid red;
    @media #{$xs-med-layout} {
      display: flex;
      justify-content: center;
    }
    .scanner-img {
      width: 12rem;
    }
  }
}

.about-cable {
  .about-heading {
    display: flex;
    justify-content: center;
    align-items: center;

    .about-logo-img {
      width: 20rem;

      @media #{$xs-med-layout} {
        width: 10rem;
      }
    }
    .about-headtext {
      width: 22rem;
      border-left: 4px solid #000;
      padding-left: 1rem;
      vertical-align: middle;
      // border: 1px solid red;

      @media #{$xs-med-layout} {
        width: 15rem;
        padding-left: 0.5rem;
      }

      h2 {
        line-height: 2.2rem;
        font-weight: 400;
        font-size: 2.2rem;
        color: #000;
        margin: 0;

        @media #{$xs-med-layout} {
          line-height: 2.2rem;
          font-size: 1.35rem;
        }

        span {
          color: #1a4789;
          font-size: 2rem;

          @media #{$xs-med-layout} {
            font-size: 1.35rem;
          }
        }
      }
    }
  }

  .about-content {
    margin: 1rem 0;
    p {
      color: #000;
      font-size: 1.2rem;
      line-height: 2.1rem;
      font-weight: 500;

      @media #{$xs-med-layout} {
        font-size: 1rem;
        line-height: 1.7rem;
      }
    }
  }
  .about-main-img {
    display: flex;
    justify-content: center;
    .about-img {
      width: 50rem;
      // margin: auto;
    }
  }
}

.milestone {
  padding: 5rem 0;

  @media #{$xs-med-layout} {
    padding: 3rem 0 1rem;
    // border: 2px solid red;
  }
  .mile-heading {
    font-size: 2.5rem;
    text-transform: uppercase;
    font-weight: 700;
    color: #1a4789;
    text-align: center;

    @media #{$xs-med-layout} {
      font-size: 1.75rem;
      line-height: 1.7rem;
    }
  }
  .mile-col {
    display: flex;
    justify-content: center;
  }
  .mile-img {
    width: 22rem;
    // border: 2px solid red;

    @media #{$xs-med-layout} {
      width: 18rem;
    }
  }
}

.certification {
  padding: 4rem 0;

  @media #{$xs-med-layout} {
    padding: 3rem 0 1rem;
    // border: 2px solid red;
  }

  .cerificate-heading {
    font-size: 3rem;
    text-transform: uppercase;
    font-weight: 700;
    color: #1a4789;
    text-align: center;

    @media #{$xs-med-layout} {
      font-size: 1.75rem;
      line-height: 1.7rem;
    }
  }
  .certifi-logos {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;

    .logo-img {
      width: 10rem;
    }
  }
}

.customers {
  .custom-heading {
    font-size: 3rem;
    text-transform: uppercase;
    font-weight: 700;
    color: #1a4789;
    text-align: center;

    @media #{$xs-med-layout} {
      font-size: 1.75rem;
      line-height: 1.7rem;
    }
  }
  .custom-sub-heading {
    font-size: 2.25rem;
    color: #000;
    font-weight: 400;
    text-align: center;
    text-transform: capitalize;

    @media #{$xs-med-layout} {
      font-size: 1.5rem;
      line-height: 1.7rem;
    }
  }
  .custom-logos {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    // justify-content: space-evenly;

    .custom-logo {
      width: 22rem;
      height: 10rem;

      @media #{$xs-med-layout} {
        width: 13rem;
        height: 10rem;
      }
    }
  }
}

.manufacture {
  padding: 5rem 0;

  @media #{$xs-med-layout} {
    padding: 3rem 0 0;
  }

  .manu-heading {
    font-size: 3rem;
    text-transform: uppercase;
    font-weight: 700;
    color: #1a4789;
    text-align: center;

    @media #{$xs-med-layout} {
      font-size: 1.75rem;
      line-height: 1.7rem;
    }
  }

  .manu-col {
    display: flex;
    justify-content: center;
  }

  .manu-img {
    width: 28rem;
    // border: 2px solid red;
  }

  .manu-sin-img {
    display: flex;
    justify-content: center;
  }
}

.presence {
  padding: 2rem 0;

  .pres-heading {
    font-size: 3rem;
    text-transform: uppercase;
    font-weight: 700;
    color: #1a4789;
    text-align: center;
    @media #{$xs-med-layout} {
      font-size: 1.75rem;
      line-height: 1.7rem;
    }
  }

  .pres-row {
    display: flex;
    align-items: center;

    .sec-location {
      ul {
        display: flex;
        flex-wrap: wrap;

        li {
          font-size: 1.2rem;
          color: #000;
          font-weight: 600;
          text-transform: capitalize;
          margin: 0 0.45rem;
          position: relative;

          &:after {
            position: absolute;
            content: "|";
            width: 4px;
            height: 4px;
            color: red;
            right: -8px;
          }
        }
      }
    }
  }
}

.refrocement {
  padding: 3rem 0;

  .heading {
    font-size: 3rem;
    text-transform: uppercase;
    font-weight: 700;
    color: #1a4789;
    text-align: center;

    @media #{$xs-med-layout} {
      font-size: 1.75rem;
      line-height: 1.7rem;
    }
  }

  .data-row-1 {
    .main-heading {
      border-bottom: 4px solid #1a4789;
      width: 80%;
      h3 {
        color: #000;
        font-size: 1.5rem;
        font-weight: 700;
        line-height: 1.5rem;
        margin-bottom: 0.5rem;

        @media #{$xs-med-layout} {
          font-size: 1.5rem;
          line-height: 1.27rem;
        }
      }
    }
    .main-content {
      margin: 0.95rem 0;
      p {
        font-size: 1.2rem;
        line-height: 2rem;
        font-weight: 500;
        color: #000;

        @media #{$xs-med-layout} {
          font-size: 1.1rem;
          line-height: 1.65rem;
        }
      }
    }
    .frp-imgs {
      display: flex;

      .frp-img {
        width: 12rem;
      }
    }
  }

  .data-row-2 {
    .row-heading2 {
      border-bottom: 2px solid #000;
      width: fit-content;
      margin-bottom: 1.5rem;

      h3 {
        font-weight: 500;
        font-size: 1.5rem;
        color: #000;
        margin-bottom: 0;
        line-height: 1.5rem;
      }
    }
    .data-list {
      ul {
        padding-left: 1rem;
        li {
          list-style: disc !important;
          font-size: 1.25rem;
          color: #000;
          font-weight: 400;
          margin: 0.35rem 0;

          @media #{$xs-med-layout} {
            font-size: 1.1rem;
            line-height: 1.65rem;
          }
        }
      }
    }

    .last-data {
      padding-left: 2.5rem;

      @media #{$xs-med-layout} {
        padding-left: 1.5rem;
      }
    }

    .prod-heading {
      h3 {
        color: #000;
        font-weight: 400;
        line-height: 2rem;
        font-size: 1.5rem;
        margin-bottom: 0.5rem;
      }
    }

    .prod-list {
      ul {
        padding-left: 1.5rem;
        li {
          list-style: disc;
          font-size: 1.12rem;
          margin: 0.4rem 0;
          font-weight: 600;
          color: #02aedc;
        }
      }
    }
  }

  .row-data-3 {
    margin: 1rem 0;

    .coat-heading {
      width: fit-content;
      border-bottom: 3px solid #1a4789;
      margin-bottom: 1rem;

      h2 {
        color: #000;
        font-weight: 700;
        font-size: 2rem;
        margin-bottom: 0.25rem;
        line-height: 2rem;
      }
    }
    .cat-list {
      ul {
        li {
          list-style: disc;
          color: #1a4789;
          font-weight: 600;
          margin: 0.5rem;
          font-size: 1.25rem;
        }
      }
    }
    .coat-img1 {
      width: 15rem;
    }
  }
}

.exhibition {
  padding: 3rem 0;

  .heading {
    h2 {
      font-size: 3.5rem;
      color: #1a4789;
      font-weight: 400;
      text-align: center;

      @media #{$xs-med-layout} {
        font-size: 1.95rem;
        line-height: 1.7rem;
      }

      span {
        font-weight: 700;
      }
    }
    h4 {
      color: #000;
      font-size: 2.5rem;
      font-weight: 400;
      text-align: center;
      text-transform: uppercase;

      @media #{$xs-med-layout} {
        font-size: 1.75rem;
        line-height: 1.7rem;
      }
    }
  }

  .exh-col {
    display: flex;
    justify-content: center;
  }
  .exh-big {
    width: 70%;
    height: 25rem;
    border-radius: 4rem;
    border: 2px solid #1a4789;

    @media #{$xs-med-layout} {
      width: 100%;
      height: 20rem;
      border-radius: 2rem;
    }
  }
  .exh-smll {
    border-radius: 2rem;
    border: 2px solid #1a4789;
    height: 25rem;

    @media #{$xs-med-layout} {
      width: 100%;
      height: 20rem;
      border-radius: 2rem;
      margin: 0.75rem 0;
    }
  }
  .extra-smll {
    border-radius: 6rem;
    border: 2px solid #1a4789;
    height: 20rem;

    @media #{$xs-med-layout} {
      width: 100%;
      height: 17rem;
      border-radius: 2rem;
      margin: 0.75rem 0;
    }
  }
}

.products {
  padding: 3rem 0;
  .heading {
    h2 {
      font-size: 3.5rem;
      color: #000;
      font-weight: 400;
      text-align: center;

      @media #{$xs-med-layout} {
        font-size: 2rem;
      }
    }
  }
  .prod-col {
    display: flex;
    justify-content: center;
  }

  .prod-det {
    width: 100%;
    margin: auto;
    display: flex;
    /* border: 1px solid red; */
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h4 {
      font-size: 2rem;
      font-weight: 400;
      text-transform: capitalize;
      position: relative;

      &:before {
        position: absolute;
        content: "";
        // background-color: red;
        height: 2rem;
        width: 2rem;
        left: -2.5rem;
        background-image: url("../images/landingimages/icon.png");
        background-size: cover;
        // background-image: url('../images/landingimages/Product\ Photograph\ \(1\)\ \(1\).png');
      }

      @media #{$xs-med-layout} {
        font-size: 1.52rem;
      }
    }

    ul {
      padding-inline-start: 2rem;
      li {
        list-style: disc;
        font-size: 1.5rem;
        font-weight: 500;
        // padding-left: 1rem;
        color: #000;
      }
    }

    .prod-img {
      width: 20rem;
      // border: 1px solid red;
    }
  }
}

.testimonial {
  padding: 3rem 0;

  .heading {
    h2 {
      font-size: 3.5rem;
      color: #1a4789;
      font-weight: 600;
      text-align: center;
      text-transform: uppercase;

      @media #{$xs-med-layout} {
        font-size: 1.95rem;
        line-height: 1.7rem;
      }
    }
  }

  .test-content {
    display: flex;
    align-items: center;
  }

  .test-data {
    border-bottom: 3px solid #1a4789;
    p {
      font-size: 1.5rem;
      color: #000;
      font-weight: 400;
      line-height: 2.5rem;
      margin: 0.5rem;

      @media #{$xs-med-layout} {
        font-size: 1.25rem;
        line-height: 1.7rem;
      }

      span {
        color: #1a4789;
        font-weight: 600;
      }
    }
  }
  .test-img {
    display: flex;
    justify-content: center;
    margin: 0.5rem 0;

    img {
      width: 15rem;
      height: 15rem;
      border-radius: 9rem;
      border: 4px solid #1a4789;
    }
  }
}

.section-ending {
  margin-top: 2rem;
  display: flex;
  grid-gap: 2rem;
  gap: 2rem;
  align-items: center;

  span {
    width: 100%;
    background-color: #000;
    height: 3px;
  }
  .dot {
    width: 3rem;
    height: 1.5rem;
    background-color: #000;
    border-radius: 5rem;

    @media #{$xs-med-layout} {
      width: 2.45rem;
      height: 1rem;
    }
  }
}

// form {
//   .form-row {
//     .download-btn {
//       button {
//         border: none;
//         background-color: #1a4789;
//         color: #fff;
//         font-size: 1.5rem;
//         text-transform: uppercase;
//         padding: 0.75rem 1.25rem;
//       }
//     }
//   }
// }
