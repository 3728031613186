.features_wrapper {
  background-color: #ffffff;
  position: relative;
  height: 100%;

  .banner-img {
    // border: 2px solid red;

    .cable-banner {
      position: absolute;
      width: 36rem;
      height: 36rem;
      top: -18rem;
      left: 50%;
      transform: translate(-46%, -50%);
      z-index: 100;

      @media #{$xx-layout} {
        width: 35rem;
        height: 35rem;
        top: -17.5rem;
      }

      @media #{$xs-med-layout} {
        width: 24rem;
        height: 25rem;
        top: -15rem;
        left: 50%;
      }
      // border: 2px solid red;
    }
  }

  .cable-imgs {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    padding: 0 2rem;

    .cableimg {
      width: 200px;
    }
  }

  .feature-heading {
    margin-top: 0.5rem;
    font-size: 3.25rem;
    font-weight: 600;
    text-align: center;
    color: #02aedc;

    @media #{$xs-med-layout} {
      font-size: 2.25rem;
      line-height: 2.25rem;
    }
  }
  .sub-heading {
    font-size: 2.2rem;
    color: #000;
    font-weight: 400;
    text-transform: capitalize;
    text-align: center;

    @media #{$xs-med-layout} {
      font-size: 1.5rem;
      line-height: 1.7rem;
    }

    span {
      font-weight: 600;
    }
  }

  .card-body {
    @media #{$xs-layout} {
      text-align: left !important;
    }

    .service_phone {
      object-fit: cover;
      border-radius: 5%;
      box-shadow: 8px 10px 12px rgba(0, 0, 0, 0.25),
        0 -1px 1px rgba(0, 0, 0, 0.13);
    }
  }

  // .feature-img{
  //   @media #{$xs-layout} {
  //     display: none;
  //   }
  // }

  .features {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.3rem;
    margin: 0.8rem 0;
    overflow-x: hidden;

    .feature-icon {
      margin: 0.3rem;
      span {
        .icons {
          background: rgba(153, 153, 153, 0.4);
          padding: 1rem;
          height: 70px;
          width: 70px;
          border-radius: 50%;
          color: rgba(0, 0, 0, 0.6);
        }
      }
    }

    .feature-text {
      margin: 0.3rem;
      h3 {
        font-size: 19px;
        line-height: 12px;
        margin-bottom: 12px;
      }
      p {
        margin-bottom: 0.1rem;
        // overflow: hidden;
        // text-overflow: ellipsis;
        // display: -webkit-box;
        // -webkit-line-clamp: 3;
        // line-clamp: 3;
        // -webkit-box-orient: vertical;
        font-size: 17px;
      }
    }
  }
  h3 {
    span {
      color: $theme-color;
    }
  }
}
