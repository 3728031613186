@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");

ul {
  list-style: none;
  padding: 0;
}
.footer {
  // background-color: rgba(244, 246, 248,.74);
  // background-color: #F4F8FD;
  background-color: #fff;
  padding: 3rem 0 0 0;

  .footer-top {
    border-bottom: 2px solid #000;
    padding-bottom: 2.8rem;

    .about-img {
      border: 2px solid #131c21;
      border-radius: 2rem;
    }

    .footer-img {
      border: 2px solid #131c21;
      border-radius: 4rem;
      margin: 1.5rem 0;
    }
    .img-heading {
      margin-top: 1rem;
      color: #000;
      text-align: center;
      font-weight: 700;
      font-size: 2.5rem;
      margin-bottom: 0.5rem;
      position: relative;

      &:after {
        position: absolute;
        content: "";
        background-color: #000;
        width: 35%;
        height: 1px;
        left: 50%;
        transform: translate(-50%, -50%);
        bottom: 0;
      }
    }
    .img-para {
      font-weight: 400;
      text-align: center;
      font-size: 1.75rem;
      color: #000;
    }
  }

  .footer-bottom {
    padding: 1rem 0;
    background-color: #000;

    p {
      margin: 0;
      color: rgba(255, 255, 255, 0.6);
    }
  }
  a {
    padding: 0.5rem 0;
  }
}
.footer-col {
  @media #{$xs-layout} {
    margin-bottom: 1rem;
  }
  .main-heading {
    color: $theme-hover-color;
    margin-bottom: 1.8rem;
    text-decoration: none;
    font-size: 1.3rem;
    line-height: 15px;
    font-weight: 600;
  }

  h4 {
    margin: 0px 0px 25px 0px;
    text-align: left;
    color: #ffffff;
    font-size: 18px;
    font-weight: bold;
    line-height: 1em;
    letter-spacing: 0px;
  }

  ul {
    li {
      &:not(:last-child) {
        margin-bottom: 0;
      }
      a {
        &:hover {
          color: $white;
          padding-left: 2px;
        }
      }
      a,
      p {
        text-transform: capitalize;
        text-decoration: none;
        padding: 0.5rem 0;
        display: block;
        transition: all 0.3s ease;
        font-size: 15px;
        font-weight: 500;
        color: #6d6e7b;
        font-style: normal;
      }
    }
  }
  .download-btn {
    margin-bottom: 1rem;
    button {
      border: none;
      background-color: #1a4789;
      color: #fff;
      border-radius: 0.51rem;
      font-size: 1.25rem;
      text-transform: uppercase;
      padding: 0.75rem 1.25rem;
      margin-bottom: 2rem;
    }
  }

  .social-links {
    a {
      display: inline;
      font-size: 1.65rem;
      margin: 0 6px 10px 0;
      text-align: center;
      line-height: 40px;
      color: #fff;
      transition: all 0.5s ease;
      padding: 5px;
      border-radius: 4px;

      .fb {
        background-color: #1877f2;
      }
      .tweet {
        background-color: #1da1f2;
      }
      .you {
        background-color: #ff0000;
      }
      .linked {
        background-color: #0a66c2;
      }
      .insta {
        background-color: #c32aa3;
      }
      .pin {
        background-color: #bd081c;
      }

      .fb,
      .insta,
      .you,
      .linked,
      .tweet,
      .pin {
        padding: 0.25rem;
        border-radius: 4px;
      }
      &:hover {
        color: rgba(255, 255, 255, 0.6);
        font-size: 1.3rem;
      }
    }
  }
  .social-bottom{
    // border: 1px solid red;
    padding-left: .75rem;
    p{
      font-size: 1rem;
      color: #000;
      margin-bottom: .5rem;
    }

  }
}

.contact-box {
  display: flex;
  align-items: center;
  justify-content: stretch;
  margin: 0.75rem 0;

  &:not(:nth-child(1)) {
    align-items: center;
  }

  .contact-icon {
    color: #fff;
    font-size: 0.8rem;
    background-color: #777;
    border-radius: 100%;
    padding: 0.5rem;
    height: 2rem;
    width: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0.5rem;
    font-size: 1.5rem;
    transition: all 0.1s;
  }

  .contact-details {
    width: 70%;
    // border: 1px solid red;
    // margin-bottom: 1rem;

    p {
      margin-bottom: 0;
    }

    .contact-loc {
      color: #000;
      font-size: 1.15rem;
    }

    a {
      color: #000;
      font-size: 1.15rem;
      &:hover {
        color: rgba(255, 255, 255, 0.8);
      }
    }
  }
}
