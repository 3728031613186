.contact-section {
  padding-top: 3rem;

  @media #{$xs-layout} {
    padding: 1rem;
  }
  .contact-content {
    h4 {
      color: $theme-hover-color;
      text-transform: uppercase;
      font-weight: 600;
      line-height: 10px;

      @media #{$xs-layout} {
        display: none;
      }
    }

    h2 {
      color: $theme-color;
      font-size: 1.5rem;
      line-height: 40px;

      @media #{$xs-med-layout} {
        font-size: 1.2rem;
        line-height: 30px;
      }
    }

    .desc-section {
      font-size: 1rem;
      font-weight: 500;
    }

    .contact-text-section {
      .contact-list {
        list-style-type: none;

        .contact-lists {
          background-color: #f4f4f4;
          margin: 0 0 20px;
          padding: 20px;
          display: flex;
          align-items: center;

          @media #{$xs-layout} {
            margin: 0 0 15px;
            padding: 12px;
          }

          .contact-icon {
            color: $theme-hover-color;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 70px !important;
            padding: 1rem;
            height: 60px;
            margin-right: 30px;
            border-radius: 8px;
            background-color: rgba(234, 31, 0, 0.2);

            svg {
              font-size: 2.5rem;
            }
          }

          .contact-text {
            .contact-tect-heading {
              color: $theme-color;
              text-transform: capitalize;
              font-weight: bold;
              letter-spacing: 1px;
              font-size: 1.1rem;
              line-height: 20px;
            }

            p {
              line-height: 20px;
            }
          }
        }
      }
    }

    .contact-form-section {
      .contact-auth-section {
        background-color: #f4f4f4;
        margin: 0 0 20px;
        padding: 20px;
        display: flex;
        align-items: center;
      }
      input,
      select {
        height: 45px;
      }
      textarea {
        height: 110px;
      }
      .form-control {
        margin: 0;
        padding: 0 15px;
        max-width: 100%;
        width: 100%;
        border: none;
        box-shadow: none;
        vertical-align: middle;
        margin: 0.5rem 0;
        font-size: 15px;
        transition: border-color 0.5s ease;
      }

      .contact-social-list {
        list-style: none;
        display: flex;
        padding: 0.2rem 1rem;
        background-color: #f4f4f4;
        margin: 0 0 20px;
        padding: 17px;
        display: flex;
        align-items: center;

        a {
          font-size: 28px;
          color: #777;
          padding: 0.2rem 0.5rem;

          .social-icons__facebook,
          .social-icons__instagram,
          .social-icons__linkedin,
          .social-icons__twitter {
            transition: all 0.9s;
          }

          .social-icons__facebook:hover {
            color: #1877f2;
          }
          .social-icons__instagram:hover {
            color: #e4405f;
          }

          .social-icons__linkedin:hover {
            color: #0a66c2;
          }

          .social-icons__twitter:hover {
            color: #1da1f2;
          }
        }
      }
    }
  }
}

.contact-form-section {
  background-color: #f3f3f378;

  .contact-authentication {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .download-btn {
    button {
      border: none;
      background-color: #1a4789;
      color: #fff;
      font-size: 1.35rem;
      text-transform: uppercase;
      padding: 0.75rem 1.1rem;
      border-radius: 0.5rem;
      margin: 0.25rem;
    }
  }

  form {
    padding: 2rem;
    border: 4px solid #333;
  }
  .form-btn {
    button {
      width: 100%;
    }
  }
}
