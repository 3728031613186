.banner_wrapper {
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 45vh, 0 100%);
  clip-path: polygon(0 0, 100% 0, 100% 45vh, 0 100%);
  height: 90vh;
  // max-height: 700px;
  background-color: #302f5b;
  position: relative;

  @media #{$xs-med-layout} {
    height: 90vh;
  }

  .banner-title {
    font-size: 3.2rem;
    color: #fff;
    font-weight: 400;
    margin-top: 1rem;

    @media #{$xx-layout} {
      font-size: 2.73rem;
      margin-top: 0.51rem;
    }

    @media #{$xs-med-layout} {
      font-size: 1.72rem;
      margin-top: 0;
    }

    span {
      display: block;
      font-weight: 600;
    }
  }

  .small-img {
    position: absolute;
    width: 12rem;
    height: 7rem;
    top: 2rem;
    right: 1%;
    z-index: 100;
    // border: 2px solid green;

    @media #{$xs-med-layout} {
      width: 10rem;
      height: 6rem;
      top: 1rem;
      right: 3%;
    }
  }
}

@media (max-width: 425px) {
  .banner-title {
    line-height: 38px;
    font-size: 32px !important;
  }
  .banner-para {
    font-size: 16px !important;
  }
}
